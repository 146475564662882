import React from 'react';
import HouseComparisonChart from './HouseComparisonChart';

function App() {
  return (
    <div className="App">
      <HouseComparisonChart />
    </div>
  );
}

export default App;