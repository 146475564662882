import React, { useState, useEffect } from 'react';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

const graphCriteria = [
  'Cena', 'Okoli', 'Cesta do Vidne', 'Mnozstvi budoucich uprav',
  'Wow efekt', 'Vnitrek domu', 'Zahrada', 'Blizkost centra'
];

const additionalFields = [
  'Web adresa', 'Mesto', 'Nazor_Jeee', 'Nazor_Veee', 'Nazor_Jooo', 'Nazor_Seee'
];

const HouseComparisonChart = () => {
  const [houses, setHouses] = useState([]);
  const [newHouse, setNewHouse] = useState({
    name: '',
    cena: 0,
    obrazek: '',
    ...Object.fromEntries(graphCriteria.map(criterion => [criterion, 0])),
    ...Object.fromEntries(additionalFields.map(field => [field, '']))
  });
  const [editingHouse, setEditingHouse] = useState(null);

  useEffect(() => {
    fetchHouses();
  }, []);

  const fetchHouses = async () => {
    try {
      const response = await fetch('/api/houses');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setHouses(data);
    } catch (error) {
      console.error('Error fetching houses:', error);
      alert('Nepodařilo se načíst data. Zkuste to prosím později.');
    }
  };

  const addHouse = async () => {
    if (newHouse.name.trim() === '') {
      alert('Prosím zadejte název domu.');
      return;
    }
    try {
      const response = await fetch('/api/houses', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newHouse),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }
      const updatedHouses = await response.json();
      setHouses(updatedHouses);
      setNewHouse({
        name: '',
        cena: 0,
        obrazek: '',
        ...Object.fromEntries(graphCriteria.map(criterion => [criterion, 0])),
        ...Object.fromEntries(additionalFields.map(field => [field, '']))
      });
    } catch (error) {
      console.error('Error adding house:', error);
      alert('Nepodařilo se přidat dům. Zkuste to prosím později.');
    }
  };

  const handleInputChange = (e, target = newHouse, setTarget = setNewHouse) => {
    const { name, value } = e.target;
    setTarget({ 
      ...target, 
      [name]: graphCriteria.includes(name) || name === 'cena' ? Number(value) : value 
    });
  };

  const deleteHouse = async (index) => {
    const houseName = houses[index].name;
    const confirmName = prompt(`Pro smazání domu "${houseName}" zadejte jeho jméno:`);
    if (confirmName === houseName) {
      try {
        const response = await fetch('/api/houses', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ index }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const updatedHouses = await response.json();
        setHouses(updatedHouses);
      } catch (error) {
        console.error('Error deleting house:', error);
        alert('Nepodařilo se smazat dům. Zkuste to prosím později.');
      }
    } else {
      alert('Zadané jméno se neshoduje. Dům nebyl smazán.');
    }
  };

  const startEditing = (index) => {
    setEditingHouse({ ...houses[index], index });
  };

  const saveEdit = async () => {
    try {
      const response = await fetch('/api/houses', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editingHouse),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Network response was not ok');
      }
      const updatedHouses = await response.json();
      setHouses(updatedHouses);
      setEditingHouse(null);
    } catch (error) {
      console.error('Error updating house:', error);
      alert('Nepodařilo se upravit dům. Zkuste to prosím později.');
    }
  };

  const cancelEdit = () => {
    setEditingHouse(null);
  };

  const data = graphCriteria.map(criterion => ({
    criterion,
    ...Object.fromEntries(houses.map(house => [house.name, house[criterion]]))
  }));

  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE'];

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-center">Porovnání domů</h2>
      
      {/* Radarový graf */}
      {houses.length > 0 && (
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Grafické porovnání</h3>
          <ResponsiveContainer width="100%" height={500}>
            <RadarChart data={data}>
              <PolarGrid />
              <PolarAngleAxis dataKey="criterion" />
              <PolarRadiusAxis angle={30} domain={[0, 10]} />
              {houses.map((house, index) => (
                <Radar
                  key={house.name}
                  name={house.name}
                  dataKey={house.name}
                  stroke={colors[index % colors.length]}
                  fill={colors[index % colors.length]}
                  fillOpacity={0.6}
                />
              ))}
              <Legend />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      )}

      {/* Seznam přidaných domů */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Přidané domy</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {houses.map((house, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4">
              <h4 className="font-bold text-lg mb-2">{house.name}</h4>
              <p className="text-sm"><span className="font-medium">Cena:</span> {house.cena} tis. EUR</p>
              {additionalFields.map(field => (
                <p key={field} className="text-sm">
                  <span className="font-medium">{field}:</span>
                  {field === 'Web adresa' ? (
                    <a 
                      href={house[field]} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-blue-600 hover:text-blue-800 hover:underline ml-1"
                    >
                      {house[field]}
                    </a>
                  ) : (
                    ` ${house[field]}`
                  )}
                </p>
              ))}
              {house.obrazek && (
                <div className="mt-2">
                  <p className="font-medium text-sm">Obrázek:</p>
                  <img src={house.obrazek} alt={house.name} className="max-w-full h-auto mt-1" />
                </div>
              )}
              <div className="mt-4 flex justify-end space-x-2">
                <button 
                  onClick={() => startEditing(index)}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded-md transition duration-300"
                >
                  Upravit
                </button>
                <button 
                  onClick={() => deleteHouse(index)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-md transition duration-300"
                >
                  Smazat
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Formulář pro přidání/úpravu domu */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4">
          {editingHouse ? 'Upravit dům' : 'Přidat nový dům'}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-span-full">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Název domu</label>
            <input
              type="text"
              id="name"
              name="name"
              value={editingHouse ? editingHouse.name : newHouse.name}
              onChange={(e) => handleInputChange(e, editingHouse || newHouse, editingHouse ? setEditingHouse : setNewHouse)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          
          <div className="col-span-full">
            <label htmlFor="cena" className="block text-sm font-medium text-gray-700 mb-1">Cena (tis. EUR)</label>
            <input
              type="number"
              id="cena"
              name="cena"
              value={editingHouse ? editingHouse.cena : newHouse.cena}
              onChange={(e) => handleInputChange(e, editingHouse || newHouse, editingHouse ? setEditingHouse : setNewHouse)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          
          {graphCriteria.map(criterion => (
            <div key={criterion} className="mb-2">
              <label htmlFor={criterion} className="block text-sm font-medium text-gray-700">{criterion}</label>
              <input
                type="number"
                id={criterion}
                name={criterion}
                value={editingHouse ? editingHouse[criterion] : newHouse[criterion]}
                onChange={(e) => handleInputChange(e, editingHouse || newHouse, editingHouse ? setEditingHouse : setNewHouse)}
                min="0"
                max="10"
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          ))}
          
          {additionalFields.map(field => (
            <div key={field}>
              <label htmlFor={field} className="block text-sm font-medium text-gray-700 mb-1">{field}</label>
              <input
                type="text"
                id={field}
                name={field}
                value={editingHouse ? editingHouse[field] : newHouse[field]}
                onChange={(e) => handleInputChange(e, editingHouse || newHouse, editingHouse ? setEditingHouse : setNewHouse)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          ))}

          <div className="col-span-full">
            <label htmlFor="obrazek" className="block text-sm font-medium text-gray-700 mb-1">Obrázek (vložte URL nebo base64 data)</label>
            <textarea
              id="obrazek"
              name="obrazek"
              value={editingHouse ? editingHouse.obrazek : newHouse.obrazek}
              onChange={(e) => handleInputChange(e, editingHouse || newHouse, editingHouse ? setEditingHouse : setNewHouse)}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            ></textarea>
          </div>
        </div>
        {editingHouse ? (
          <div className="mt-6 flex justify-end space-x-4">
            <button 
              onClick={cancelEdit}
              className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-md transition duration-300"
            >
              Zrušit
            </button>
            <button 
              onClick={saveEdit}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md transition duration-300"
            >
              Uložit změny
            </button>
          </div>
        ) : (
          <button 
            onClick={addHouse} 
            className="mt-6 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300"
          >
            Přidat dům
          </button>
        )}
      </div>
    </div>
  );
};

export default HouseComparisonChart;